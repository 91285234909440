exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-default-index-jsx": () => import("./../../../src/pages/default/index.jsx" /* webpackChunkName: "component---src-pages-default-index-jsx" */),
  "component---src-pages-default-jsx": () => import("./../../../src/pages/default/[...].jsx" /* webpackChunkName: "component---src-pages-default-jsx" */),
  "component---src-pages-effortless-accessibility-index-jsx": () => import("./../../../src/pages/effortless-accessibility/index.jsx" /* webpackChunkName: "component---src-pages-effortless-accessibility-index-jsx" */),
  "component---src-pages-effortless-accessibility-jsx": () => import("./../../../src/pages/effortless-accessibility/[...].jsx" /* webpackChunkName: "component---src-pages-effortless-accessibility-jsx" */),
  "component---src-pages-finalsite-index-jsx": () => import("./../../../src/pages/finalsite/index.jsx" /* webpackChunkName: "component---src-pages-finalsite-index-jsx" */),
  "component---src-pages-finalsite-jsx": () => import("./../../../src/pages/finalsite/[...].jsx" /* webpackChunkName: "component---src-pages-finalsite-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-techknow-solutions-index-jsx": () => import("./../../../src/pages/techknow-solutions/index.jsx" /* webpackChunkName: "component---src-pages-techknow-solutions-index-jsx" */),
  "component---src-pages-techknow-solutions-jsx": () => import("./../../../src/pages/techknow-solutions/[...].jsx" /* webpackChunkName: "component---src-pages-techknow-solutions-jsx" */),
  "component---src-pages-viv-index-jsx": () => import("./../../../src/pages/viv/index.jsx" /* webpackChunkName: "component---src-pages-viv-index-jsx" */),
  "component---src-pages-viv-jsx": () => import("./../../../src/pages/viv/[...].jsx" /* webpackChunkName: "component---src-pages-viv-jsx" */),
  "component---src-pages-web-village-index-jsx": () => import("./../../../src/pages/web-village/index.jsx" /* webpackChunkName: "component---src-pages-web-village-index-jsx" */),
  "component---src-pages-web-village-jsx": () => import("./../../../src/pages/web-village/[...].jsx" /* webpackChunkName: "component---src-pages-web-village-jsx" */),
  "component---src-pages-website-guardian-index-jsx": () => import("./../../../src/pages/website-guardian/index.jsx" /* webpackChunkName: "component---src-pages-website-guardian-index-jsx" */),
  "component---src-pages-website-guardian-jsx": () => import("./../../../src/pages/website-guardian/[...].jsx" /* webpackChunkName: "component---src-pages-website-guardian-jsx" */)
}

